import React, { Suspense } from 'react';
// import logo from './logo.svg';
// import './App.css';
import './custom.scss';
import './App.scss';
import Dashboard from './pages/Dashboard';
import { Spinner } from 'react-bootstrap';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';


function App() {
  return (
    <Dashboard />
  );
}

export default App;
