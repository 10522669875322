import { Accordion, Col, Container, Row, Spinner } from "react-bootstrap";
import Navigation from "../components/Navigation";

import './Dashboard.scss';
import FlowEditor from "../components/FlowEditor";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCog, faCubes, faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./Home";
import Create from "./Create";

const ComingSoon = () => {

    return (
        <Container className="coming-soon">
            <Row>
                <Col>
                    <h1>Coming soon</h1>
                    <Spinner animation="border" />
                </Col>
            </Row>

        </Container>
    )
}


const ErrorPage = () => {

    return (
        <Container className="coming-soon">
            <Row>
                <Col>
                    <FontAwesomeIcon icon={faCancel} size="lg" />
                    <h1>Error</h1>
                    <p>The resource you are looking for is not found.</p>

                </Col>
            </Row>

        </Container>
    )
}


const router = createBrowserRouter([
    {
        path: "/",
        element: <Create />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/create",
        element: <Create />,
    },
    {
        path: "/deployments",
        element: <ComingSoon />
    },
    {
        path: "/marketplace",
        element: <ComingSoon />,
    },
    {
        path: "/:account/:repo",
        element: <FlowEditor />,
    },
]);

const Dashboard = () => {

    const [running, setRunning] = useState(false)
    const [app, setApp] = useState()

    const [activeKey, setActiveKey] = useState<string | null>("0");

    const handleAccordionClick = (key: string) => {
        if (activeKey !== key)
            setActiveKey(activeKey === key ? null : key);
    };

    return (
        <>
            <div id="navbar">
                <a className="logo" href="/"><img src="/ized.svg" /></a>
                <Navigation />
                <div className="nav-right">
                    <FontAwesomeIcon icon={faPuzzlePiece} />
                    <FontAwesomeIcon icon={faCog} />
                </div>

            </div>
            <div id="content">

                <RouterProvider router={router} />
            </div>

        </>

    );
};

export default Dashboard;