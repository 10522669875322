import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faRobot, faCalendarAlt, faUserShield, faClipboardList, faChalkboardTeacher, faClock, faBolt, faChartBar, faMagicWandSparkles } from '@fortawesome/free-solid-svg-icons';

interface AppTemplate {
    title: string;
    icon: IconDefinition;
    description: string;
    available?: boolean;
    spec?: any;
}
const HEAD_COMMENT = {
    kind: 'Comment',
    uuid: crypto.randomUUID(),
    spec: {
        text: `
This is a comment. You can use it to describe the purpose of this AI instruction, or to add notes for yourself or your team. Comments are not executed during the flow, so feel free to write anything you like here.

To add a new step to the flow, click the "+" button on the right side of the current step. You can choose from different types of steps.

To edit the content of a step, click on the step and modify the text or code in the editor on the right side of the screen.

Once ready, you can run the flow by clicking the "Play" button at the top right of the screen. A preview chatbox will appear, allowing you to interact with your own chatbot in real time.
`,
    },
}


const AI_Chatbot = {
    kind: 'ChatBot',
    apiVersion: 'v1',
    metadata: {
        name: 'sample-chatbot',
        title: 'Chat Bot',
    },
    spec: {
        steps: [
            HEAD_COMMENT,
            {
                kind: 'AiInstruction',
                uuid: crypto.randomUUID(),
                spec: {
                    model: 'gpt-40-mini',
                    text: `
As a helpful and proactive chatbot for Acme Inc., your primary role is to assist clients with their inquiries, provide support, and help them navigate our product offerings.
`,
                },
            },
            {
                kind: 'AiInstruction',
                uuid: crypto.randomUUID(),
                spec: {
                    model: 'gpt-40-mini',
                    text: `
When interacting with clients:

- Greet them warmly and ask how you can assist today.
- If they require support, ask for specifics and guide them through troubleshooting steps or solutions efficiently.
- Proactively recommend Acme's top products that may benefit them based on their needs, highlighting any key features or current promotions.
- Subtly upsell by suggesting complementary products or upgraded options that enhance the value of their current purchase or interest.
- Throughout the conversation, maintain a friendly and knowledgeable tone, making the client feel valued and supported.
- Remember, the goal is to assist clients while also helping them discover how Acme's products can further meet their needs.
- If you encounter any issues or need to escalate a query, transfer the client to a human agent for further assistance. Let the client know that a team member will be with them shortly.
`,
                },
            },
        ],
    },

}


const Fantasy_Chatbot = {
    kind: 'ChatBot',
    apiVersion: 'v1',
    metadata: {
        name: 'fantasy-chatbot',
        title: 'Fantasy Chat Bot',
    },
    spec: {
        steps: [
            HEAD_COMMENT, 
            {
                kind: 'AiInstruction',
                uuid: crypto.randomUUID(),
                spec: {
                    model: 'gpt-40-mini',
                    text: `
As a magical and interactive chatbot, your primary role is to guide children through fantastical adventures, helping them explore enchanted lands, meet mystical creatures, and solve whimsical puzzles. 
`,
                },
            },
            {
                kind: 'AiInstruction',
                uuid: crypto.randomUUID(),
                spec: {
                    model: 'gpt-40-mini',
                    text: `
When interacting with children:

- Greet them with an enchanting welcome, inviting them to begin their adventure in a friendly and magical tone.
- Encourage them to choose their path by offering different fantasy scenarios, like exploring a dragon's lair, meeting a wise old wizard, or solving a fairy's riddle.
- Provide gentle guidance and clues as they solve puzzles or face challenges, always keeping the experience fun and engaging.
- Use imaginative language and vivid descriptions to bring the fantasy world to life, making the child feel like they are truly part of the adventure.
- If they seem stuck or need help, offer hints or alternative paths without giving away the solution, fostering problem-solving skills.
- Throughout the journey, reinforce positive behaviors such as curiosity, creativity, and kindness.
- If needed, gracefully exit the conversation by inviting them to return for more adventures another time or offer to save their progress.
- Remember, the goal is to create a magical and immersive experience that captivates the child's imagination while promoting learning and exploration.
`,
                },
            },
        ],
    },
};



const Kids_School_Tutor_Chatbot = {
    kind: 'ChatBot',
    apiVersion: 'v1',
    metadata: {
        name: 'school-tutor-chatbot',
        title: 'Kid\'s School Tutor',
    },
    spec: {
        steps: [
            HEAD_COMMENT,
            {
                kind: 'Comment',
                spec: {
                    text: `NOTE: Customize the tutor's degree and subject of interest here to tailor the chatbot's expertise.
For example:
- Degree: Elementary School, Middle School, High School
- Subject: Math, Science, English, History, etc.
You can specify multiple subjects or focus on one area based on the student's needs.`
                }
            },
            {
                kind: 'AiInstruction',
                uuid: crypto.randomUUID(),
                spec: {
                    model: 'gpt-40-mini',
                    text: `
As a friendly and knowledgeable school tutor, your primary role is to assist children with their studies, helping them understand concepts, complete assignments, and prepare for exams.`,
                },
            },
            {
                kind: 'AiInstruction',
                uuid: crypto.randomUUID(),
                spec: {
                    model: 'gpt-40-mini',
                    text: `
When interacting with students:

- Begin by asking what subject or topic they need help with today.
- Provide clear and easy-to-understand explanations tailored to the student's grade level and subject matter.
- Guide them through practice problems, offering hints and tips to help them solve the problems on their own.
- Encourage critical thinking by asking questions that prompt the student to explore the topic further and apply what they've learned.
- Provide positive reinforcement and encouragement to boost the student's confidence and motivation.
- Offer additional resources or suggest topics for further study to help the student excel in their subject.
- If the student struggles with a concept, break it down into simpler steps and provide alternative explanations until they grasp the material.
- If appropriate, suggest study schedules or tips for preparing for exams, tailored to the student’s learning style and pace.
- Throughout the session, maintain a supportive and patient tone, ensuring that the student feels comfortable and engaged.
`,
                },
            },
        ],
    },
};




const AppTypes: AppTemplate[] = [
    {
        title: "Chatbot",
        icon: faRobot,
        description: "Build a custom chatbot that can answer questions, take orders, and more",
        available: true,
        spec: AI_Chatbot
    },
    {
        title: "Booking Calendar",
        icon: faCalendarAlt,
        description: "Set up a booking calendar to schedule appointments and manage availability",
    },
    {
        title: "On-call Schedule",
        icon: faUserShield,
        description: "Create and manage on-call schedules for your team, ensuring coverage at all times",
    },
    {
        title: "Personal Assistant",
        icon: faClipboardList,
        description: "Build a virtual personal assistant to manage tasks, reminders, and more",
    },
    {
        title: "Kid's Class Tutor",
        icon: faChalkboardTeacher,
        description: "Create a kid-friendly class tutor that can help with learning and homework tasks",
        available: true,
        spec: Kids_School_Tutor_Chatbot
    },
    {
        title: "Fantasy Chatbot",
        icon: faMagicWandSparkles,
        description: "A magical companion where kids chat with enchanted characters",
        available: true,
        spec: Fantasy_Chatbot
    },
    {
        title: "Scheduled Task",
        icon: faClock,
        description: "Schedule a task to run at a specific time, automating routine processes",
    },
    {
        title: "Event Trigger",
        icon: faBolt,
        description: "Trigger a task when an event occurs, automating responses to specific conditions",
    },
    {
        title: "Data Processor",
        icon: faChartBar,
        description: "Process and transform data from various sources, applying filters and generating reports",
    },
    {
        title: "Web Scraper",
        icon: faRobot,
        description: "Automatically extract data from websites, monitor changes, and store the information for analysis",
    },
    {
        title: "Notification System",
        icon: faBolt,
        description: "Set up alerts and notifications based on specific conditions or thresholds being met",
    },
    {
        title: "Workflow Automation",
        icon: faClipboardList,
        description: "Automate complex multi-step processes, integrating various tools and services",
    },
    {
        title: "Content Generator",
        icon: faChalkboardTeacher,
        description: "Generate custom content, such as blog posts, emails, or social media posts, based on user input",
    },
    {
        title: "Recommendation Engine",
        icon: faChartBar,
        description: "Provide personalized recommendations for products, content, or services based on user behavior",
    },
    {
        title: "Form Handler",
        icon: faClipboardList,
        description: "Create custom forms for data collection and automatically process or route the submissions",
    },
    {
        title: "Sentiment Analyzer",
        icon: faChartBar,
        description: "Analyze text from social media, reviews, or feedback to determine sentiment and gain insights",
    },
    {
        title: "Customer Support Bot",
        icon: faRobot,
        description: "Build a bot that handles customer queries, provides support, and escalates issues when necessary",
    },
    {
        title: "Inventory Management",
        icon: faClipboardList,
        description: "Track inventory levels, manage stock, and automate reordering based on usage patterns",
    },
    {
        title: "Email Campaign Manager",
        icon: faCalendarAlt,
        description: "Design, schedule, and send personalized email campaigns, tracking open rates and engagement",
    },
    {
        title: "Survey Creator",
        icon: faClipboardList,
        description: "Create custom surveys to gather feedback and analyze responses in real-time",
    },
    {
        title: "API Integrator",
        icon: faBolt,
        description: "Seamlessly connect and interact with multiple APIs, automating data flows and processing",
    },
    {
        title: "Virtual Assistant",
        icon: faClipboardList,
        description: "Build a virtual assistant that can manage calendars, set reminders, and perform other tasks",
    },
    {
        title: "Social Media Manager",
        icon: faCalendarAlt,
        description: "Automate social media posting, monitor engagement, and respond to interactions across platforms",
    }
];

export default AppTypes;
