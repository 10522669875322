import ChatBotApp from "../apps/ChatBotApp"

const Components = {
    'ChatBot': ChatBotApp,
}

const Preview = ({ app }: { app: object }) => {
    const AppComponent = Components[(app as any).kind as keyof typeof Components]
    if(!AppComponent)
        return null

    return (
        <AppComponent conf={app} />
    )
}

export default Preview