import { faCartFlatbed, faCubes, faLayerGroup, faStore } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Nav } from "react-bootstrap"

const Navigation = () => {
    return (
        <Nav activeKey="/">
            <Nav.Item>
                <Nav.Link href="/create"><FontAwesomeIcon icon={faCubes} /> &nbsp;Create</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/deployments"><FontAwesomeIcon icon={faLayerGroup} /> &nbsp; My Apps</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/marketplace"><FontAwesomeIcon icon={faStore} /> &nbsp;  Marketplace</Nav.Link>
            </Nav.Item>
        </Nav>
    )
}

export default Navigation