import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Badge, Button, Col, Container, Form, Row } from "react-bootstrap"
import AppTypes from "../data/AppTypes"
import { create } from "domain"

const Home = () => {
    const create = (spec: any) => {
        const uuid = crypto.randomUUID().substring(0, 8)
        const name = `${spec.metadata.name}-${uuid}`
        const key = `me/${name}`
        spec.metadata.name = name
        localStorage.setItem(key, JSON.stringify(spec))

        window.location.href = `/${key}`

    }
    return (
        <Container className="create">
            <Row>
                <Col>
                    <h2>What type of AI app are you creating today?</h2>
                </Col>
            </Row>

            <Row>
                <Col>
                Choose wisely, or create them all!
                </Col>
            </Row>
            <Row className="app-types">
                {AppTypes.filter((t) => t.available).sort((a, b)=> a.title.localeCompare(b.title)).map((appType, index) => {
                    return (
                        <Col sm={6} md={4} className="app-type">
                            <div className="wrapper">
                                <FontAwesomeIcon icon={appType.icon} size="lg" />
                                <h3>{appType.title}</h3>
                                <p>{appType.description}</p>
                                <Button variant="success" onClick={() => create(appType.spec)}>Create</Button>
                            </div>
                        </Col>
                    )
                })}
                {AppTypes.filter((t) => !t.available).sort((a, b)=> a.title.localeCompare(b.title)).map((appType, index) => {
                    return (
                        <Col sm={6} md={4} className="app-type">
                            <div className="wrapper">
                                <FontAwesomeIcon icon={appType.icon} size="lg" />
                                <h3>{appType.title}</h3>
                                <p>{appType.description}</p>
                                <Button disabled variant="secondary">Coming Soon</Button>
                            </div>
                        </Col>
                    )
                })}
            </Row>

        </Container>
    )
}

export default Home