import { faPlay } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { Button, Card, FormControl } from "react-bootstrap"

import './ChatBotApp.scss'

const ChatBotApp = ({ conf }: { conf: any }) => {
    const [chatMessage, setChatMessage] = useState('')
    const [chatLog, setChatLog] = useState([])
    const [isConnected, setIsConnected] = useState(false);
    const [confSent, setConfSent] = useState(false)
    const session = crypto.randomUUID()

    const wsRef = useRef(null);  // Use useRef to store the WebSocket instance
    const chatRef = useRef(null);
    const endOfMessagesRef = useRef(null);


    const connectWebSocket = () => {
        const ws = new WebSocket('ws://localhost:5050/ws/' + session);
        ws.onopen = () => {
            setIsConnected(true);
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.kind === 'status') {
                if (data.status === 'connected' && !confSent) {
                    setConfSent(true)
                    try {
                        // @ts-ignore
                        wsRef.current.send(JSON.stringify({
                            "app": conf,
                        }))
                    } catch (e) { }
                }
            } else if (data.kind === 'chat') {
                // @ts-ignore
                setChatLog((oldChatLog) => {
                    // if data is string, add single element. If it is list, add all elements individually
                    if (Array.isArray(data)) {
                        return [...oldChatLog, ...data]
                    } else {
                        return [...oldChatLog, data]
                    }
                })
            } else {

                // setRuns(oldArray => [...oldArray, data]);
            }

        };

        ws.onclose = () => {
            setIsConnected(false);
            setTimeout(() => {
                connectWebSocket(); // Reconnect after a delay
            }, 5000); // Retry connection after 5 seconds
        };

        ws.onerror = (error) => {
            ws.close(); // Close the WebSocket connection to trigger `onclose`
        };

        // @ts-ignore
        wsRef.current = ws; // Assign the WebSocket instance to the ref
    };

    useEffect(() => {
        // Connect WebSocket on component mount
        connectWebSocket();

        // Cleanup function to close WebSocket when the component unmounts
        return () => {
            if (wsRef.current) {
                // @ts-ignore 
                wsRef.current.close();
            }
        };
    }, []);

    useEffect(() => {
        if (chatRef.current) {
            // @ts-ignore
            chatRef.current.scrollTop = chatRef.current.scrollHeight;

            // Option 2: Scroll to the bottom using a dummy element
            if (endOfMessagesRef.current) {
                // @ts-ignore
                endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [chatLog])





    const send = () => {
        // @ts-ignore
        if (wsRef && wsRef.current && chatMessage.trim().length > 0) {
            // @ts-ignore
            const message = {
                role: "user",
                content: chatMessage
            }
            // @ts-ignore
            wsRef.current.send(JSON.stringify({ kind: "chat", ...message }))
            // @ts-ignore
            setChatLog([...chatLog, message])
            setChatMessage('')
        }
    }

    return (
        <Card className="chatbox-app">
            <Card.Header className="title">
                {conf.metadata.title}
            </Card.Header>
            <Card.Body className="messages" ref={chatRef}>
                <div className="messages-wrapper">
                    {chatLog.map((msg, idx) => {
                        return (
                            <div key={idx} className={`message role-${(msg as any).role}`}>
                                <div className="message-container">
                                    {(msg as any).content}
                                </div>
                            </div>
                        )
                    })}
                    <div ref={endOfMessagesRef}>&nbsp;</div>
                </div>
            </Card.Body>
            <Card.Footer className="controls">
                <div style={{
                    flexGrow: 1,
                    padding: '0 10px 0 0',
                }}>
                    <FormControl
                        name="chat"
                        type="textarea"
                        placeholder={`Chat with ${conf.metadata.title}`}
                        style={{
                            width: '100%',
                            padding: '10px',
                            margin: '0 10px 0 0',
                        }}
                        value={chatMessage}
                        onChange={(evt) => {
                            // @ts-ignore
                            setChatMessage(evt.target.value)
                        }}
                        onKeyDown={(evt) => {
                            // Capture ctrl + enter
                            if (evt.key === 'Enter' && !evt.shiftKey) {
                                send()
                            }
                        }}

                    />

                </div>

                <Button variant="primary" type="submit" onClick={send}>
                    <FontAwesomeIcon icon={faPlay} size="sm" />
                </Button>
            </Card.Footer>
        </Card>



    )
}

export default ChatBotApp