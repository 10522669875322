import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";


const adjustHeight = (control: any) => {
    if (control.current){
            
        // @ts-ignore
        control.current.style.height = "auto";

        // @ts-ignore
        let nx = control.current.scrollHeight
        // @ts-ignore
        let ch = control.current.clientHeight
        if (ch >= 300){
            // @ts-ignore
            control.current.style.overflowY = "auto"
        } else {
            // @ts-ignore
            control.current.style.overflowY = "hidden"
        }

        if(nx > 300){
            // nx = 300 
        } else if (nx < 40){
            nx = 0
        }

        // @ts-ignore
        control.current.style.height = (nx)+"px";
    }
}

const AiInstruction = ({ config, onChange }: { config: object, onChange: (conf: string) => void }) => {

    const [curValue, setCurValue] = useState((config as any).spec.text)

    const control = useRef()

    useEffect(()=>{
        adjustHeight(control)
    }, [control])


    return (
        <>

            <Form.Control
                as="textarea"
                onChange={(evt) => {
                    (config as any).spec.text = evt.target.value
                    onChange(config as any)
                    setCurValue(evt.target.value)
                }}
                onKeyUp={()=>adjustHeight(control)}
                
                value={curValue}
                // @ts-ignore
                ref={control}
            />
        </>
    )
}

const Comment = ({ config, onChange }: { config: object, onChange: (conf: string) => void }) => {

    const [curValue, setCurValue] = useState((config as any).spec.text)

    const control = useRef()

    useEffect(()=>{
        adjustHeight(control)
    }, [control])


    return (
        <>

            <Form.Control
                as="textarea"
                onChange={(evt) => {
                    (config as any).spec.text = evt.target.value
                    onChange(config as any)
                    setCurValue(evt.target.value)
                }}
                onKeyUp={()=>adjustHeight(control)}
                
                value={curValue}
                // @ts-ignore
                ref={control}
                className="comment"
            />
        </>
    )
}

const Components: { [key: string]: React.FC<any> } = {
    AiInstruction: AiInstruction,
    Comment: Comment,
    Other: AiInstruction
}

export type ConfigType = {
    kind: keyof typeof Components; // Ensures 'kind' is one of the keys of Components
    uuid: string
};

type StepProps = {
    config: ConfigType;
    onChange: (conf: object) => void;
    uuid: string;
}


const Step = ({ config, onChange }: StepProps) => {
    const DynamicComponent = Components[config.kind]
    if (!DynamicComponent) return null; // Handle the case where the component is not found

    return (
        <div>
            <DynamicComponent config={config} onChange={onChange} />
        </div>
    )
}

export default Step