
import { useEffect, useState } from "react";
import { customerSupport } from "../data/DemoApp";
import React from "react";
import Step, { ConfigType } from "./Step";
import { Accordion, Badge, Button, ButtonGroup, Col, Container, Dropdown, DropdownButton, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay, faCode, faCog, faComment, faCommentAlt, faCommentDollar, faCommentDots, faCommentSlash, faLanguage, faLevelDownAlt, faLevelUpAlt, faLockOpen, faMagicWandSparkles, faPlay, faPlayCircle, faPlus, faSave, faStop, faStopCircle, faTrashAlt, faTrashArrowUp } from "@fortawesome/free-solid-svg-icons";
import Preview from "./Preview";
import { Link, useLoaderData, useParams } from "react-router-dom";


type StepToolbarProps = {
    pos: number;
    addStep: (pos: number, step: ConfigType) => void;
}

const stepKinds = {
    AiInstruction: {
        uuid: null,
        kind: 'AiInstruction',
        spec: {
            model: 'gpt-40-mini',
            text: '',
        },
    },
    PythonScript: {
        uuid: null,
        kind: 'PythonScript',
        spec: {
            code: '',
        },
    },
    Comment: {
        uuid: null,
        kind: 'Comment',
        spec: {
            text: ''
        }
    }
}


const StepToolbar = ({ pos, addStep }: StepToolbarProps) => {
    return (

        <ButtonGroup className='step-toolbar' size="sm" >

            <Button variant="light" >
                <FontAwesomeIcon icon={faCog} />
            </Button>


            <DropdownButton
                variant="light"
                size="sm"
                as={ButtonGroup}
                title={<>
                    <FontAwesomeIcon icon={faPlus} />
                    <FontAwesomeIcon icon={faLevelUpAlt} />
                </>}>
                <Dropdown.Item onClick={() => addStep(pos, stepKinds.AiInstruction as any)} eventKey="1"><FontAwesomeIcon icon={faLanguage} /> AI Instruction</Dropdown.Item>
                <Dropdown.Item onClick={() => addStep(pos, stepKinds.PythonScript as any)} eventKey="2"><FontAwesomeIcon icon={faCode} /> Python script</Dropdown.Item>
            </DropdownButton>


            <DropdownButton
                variant="light"
                size="sm"
                as={ButtonGroup}
                title={<>
                    <FontAwesomeIcon icon={faPlus} />
                    <FontAwesomeIcon icon={faLevelDownAlt} />
                </>}>
                <Dropdown.Item onClick={() => addStep(pos + 1, stepKinds.AiInstruction as any)} eventKey="1"><FontAwesomeIcon icon={faLanguage} /> AI Instruction</Dropdown.Item>
                <Dropdown.Item onClick={() => addStep(pos + 1, stepKinds.PythonScript as any)} eventKey="2"><FontAwesomeIcon icon={faCode} /> Python script</Dropdown.Item>
            </DropdownButton>


            <Button variant="light">
                <FontAwesomeIcon icon={faTrashAlt} />
            </Button>

        </ButtonGroup>
    )
}

type FlowEditorProps = {
}

const FlowEditor = ({ }: FlowEditorProps) => {
    const [app, setApp] = useState();
    const [apps, setApps] = useState();
    const [runningApp, setRunningApp] = useState<any>()
    const [running, setRunning] = useState(false)
    const { account, repo } = useParams<{ account: string, repo: string }>();



    const [steps, setSteps] = useState()
    const stepChange = (idx: number, newConf: object) => {
        // @ts-ignore
        steps[idx] = newConf
        setSteps(steps)
    }

    useEffect(() => {
        if (app) {
            setSteps((app as any).spec.steps.map((step: any) => {
                // @ts-ignore
                step.uuid = crypto.randomUUID()
                return step
            }))
        }
    }, [app])

    const refreshApps = () => {
        console.log('refreshing apps')
        const currentKey = `${account}/${repo}`
        console.log(currentKey)
        const items = []
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            console.log(key)
            if (key && key.startsWith('me/')) {
                // @ts-ignore
                const value = JSON.parse(localStorage.getItem(key));
                console.log(value)
                items.push({
                    key: key,
                    app: value
                })

                if (key === currentKey) {
                    setApp(value);
                }
            }
        }
        // @ts-ignore 
        setApps(items)
    }
    useEffect(() => refreshApps, [account, repo])

    const save = () => {
        localStorage.setItem(`${account}/${repo}`, JSON.stringify(app));
        refreshApps()
    }


    const addStep = (pos: number, step: ConfigType) => {
        if (step.uuid === null)
            step.uuid = crypto.randomUUID()

        // @ts-ignore
        setSteps(oldSteps => {
            // @ts-ignore
            let newSteps = [...oldSteps]
            if (newSteps.length == pos) {
                // @ts-ignore
                newSteps.push(step)
            } else
                newSteps.splice(pos, 0, step as any)

            return newSteps
        })
    }


    const run = () => {
        setRunning(true)
        // @ts-ignore
        setRunningApp({ ...app, spec: { ...app.spec, steps: steps } })
    }
    const stop = () => {
        setRunning(false)
        setRunningApp(undefined)
    }

    return (<>

        {app && <div className="flow-editor">
            <div className="toolbar">
                <div>
                    / {account} / {repo} &nbsp;
                    <Badge bg="secondary">private</Badge>
                </div>
                <div>
                    <ButtonGroup className="align-right" >
                        <Button variant="secondary" onClick={save}>
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                        {!running && <Button variant="secondary" onClick={run}>

                            <FontAwesomeIcon icon={faPlay} />
                        </Button>}
                        {running && <Button variant="secondary" onClick={stop}>

                            <FontAwesomeIcon icon={faStop} />
                        </Button>}
                    </ButtonGroup>
                </div>
            </div>
            <div className="main">
                <div className="sidebar">
                    <h5>Your Apps
                        <Button href="/create" size="sm" variant="secondary">+</Button>
                    </h5>
                    {(apps as any).length > 0 && (apps as any).map((app: any, idx: number) => {
                        return (
                            <a href={`/${app.key}`} className={app.key===`${account}/${repo}`?"active":""} key={idx}> {app.app.metadata.title} </a>
                        )
                    })}
                </div>
                <div className="worksheet">
                    <Form.Control
                        as="textarea"
                        className="h2"
                        // @ts-ignore
                        value={app.metadata.title}
                        onChange={(evt) => {
                            // @ts-ignore
                            setApp({ ...app, metadata: { ...app.metadata, title: evt.target.value } })
                        }}
                        rows={1}
                    />

                    {steps && <>
                        {steps && (steps as any).map((step: any, idx: number) => {
                            return (
                                <div key={idx} className={`step-wrapper step-${step.kind}`}>
                                    <Step uuid={step.uuid} config={step as ConfigType} onChange={(conf) => stepChange(idx, conf)} />
                                </div>
                            )
                        })}
                        <Button variant="light" onClick={() => addStep((steps as any).length, { ...stepKinds.AiInstruction } as any)}><FontAwesomeIcon icon={faLanguage} /> AI Instruction</Button>
                        {/* <Button variant="light" onClick={() => addStep(steps.length, {...stepKinds.PythonScript} as any)}><FontAwesomeIcon icon={faCode} /> Python script</Button> */}
                        <Button variant="light" onClick={() => addStep((steps as any).length, { ...stepKinds.Comment } as any)}><FontAwesomeIcon icon={faCommentDots} /> Comment</Button>
                    </>}
                </div>
                {running && <div className="preview">
                    <Preview app={app as any} />
                </div>}
            </div>
        </div>}

    </>);
}

export default FlowEditor